html  {
    scrollbar-gutter: auto;
    overflow-y: scroll
}

/* the MDX popup container draws a horizontal line when it opens and the page content shifts past the viewport */
.mdxeditor-popup-container {
    border: 0px !important;
}

/* Force the height of the background to be fill the screen */
html, body, #app, #app > div {
    min-height: 100vh;
    margin: 0px;
}